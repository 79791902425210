
import { useEffect, useState } from "react"
import axios from "axios";
import useStorage from "./useStorage";
const useWhiteListedIP = () => {
  const [listed, setListed] = useState(false)
  //const [ipUser] = useStorage()
  useEffect(() => {
    fetchAPI();
  }, [listed])

  async function fetchAPI() {

       await axios("/checkWhiteListedIP")
        .then((res) => {
          if (typeof window === 'undefined') {
            return;
          } else {
            const ipValue= res.data.isWhiteListedIP;
            JSON.stringify(window.localStorage.setItem("ipUser", ipValue));
            setListed(ipValue)
            //setListed(true) /*true keeps the user logged in but log out button will hide because it's ip based login*/
          }
        })
  }

  return [listed]
}
export default useWhiteListedIP
