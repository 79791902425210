import React, { useEffect, useState } from "react";

import { LogoutBase } from "./logout.js";
import "./logout.m.css";

const Logout = () => {

  const textInput = React.createRef();
  const textInputMob = React.createRef();

  const [isLogoutFormOpen, setIsLogoutFormOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true)
  }, [])

  const toggleLogoutForm = () => {
    setIsLogoutFormOpen(!isLogoutFormOpen);
    textInput.current.focus();
    textInputMob.current.focus();
  };

  const formStyle = {
    display: isLogoutFormOpen ? "flex" : "none",
    //margin: this.state.isLogoutFormOpen ? "-16px 0" : ""
  };
  const initialStyle = {
    display: isLogoutFormOpen ? "none" : "inline-block"
  };
  const logoutStyle = {
    display: isLogoutFormOpen ? "block" : "none"
  };
  return (
    <React.Fragment>
      <div styleName="menubar-logout">
      <div styleName="close-icon" onClick={toggleLogoutForm} style={logoutStyle}>
          <i className="ri-close-line"></i>
        </div>

      <button styleName="btn_logout" onClick={toggleLogoutForm} style={initialStyle}>
        <i className="ri-user-line"></i>
      </button>
      </div>
      <div styleName="logout-wrapper" style={logoutStyle}>
        <div styleName="logout-wrapper-inside">
          <LogoutBase
            toggleLogoutForm={toggleLogoutForm}
            initialStyle={initialStyle}
            innerRef={textInputMob}
            ref={null}
          />
        </div>
      </div>
    </React.Fragment>
  );

}

export { Logout };
