
import { useEffect, useState } from "react"
import axios from "axios";
const useLinks = (linkType) => {
  const [link, setLink] = useState("")
  useEffect(() => {
    fetchAPI();
  }, [link])
  async function fetchAPI() {
    await axios(`/geturls/${linkType}`)
      .then((res) => setLink(res.data.link))
  }
  return [link]
}
export default useLinks;
