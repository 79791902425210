import { useEffect, useState } from "react";
import axios from "axios";
const useMessage = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  const setNullMessage = () => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 15000);
  };
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    } else {
      const searchQueryObj = window.location.href;
      const errorIncludes = searchQueryObj.includes("errorMessage");

      if (errorIncludes) {
        setErrorMessage("Tarkista käyttäjätunnus ja salasana.");
      /*  setTimeout(() => {
        setErrorMessage("Tarkista käyttäjätunnus ja salasana.");
        setTimeout(() => {
         window.location.href = '/';
        }, 15000);*/

      }
    }
    setNullMessage();
  }, [])


  return [errorMessage]
}
export default useMessage;
