import React from "react";
import { number, string } from "prop-types";
import "./header-time-stamp.m.css";

const HeaderTimeStamp = ({ timeStamp, prefix = "" }) => {
  const options = {
    weekday: "short",
    /*year: "numeric",*/
    month: "numeric",
    day: "numeric"/*,
    hour: "numeric",
    minute: "numeric"*/
  };

  const time = new Date(timeStamp).toLocaleString("fi-FI", options);
  return (
    timeStamp && (
      <div styleName="date-time" className="date-time">
        <span> {prefix}</span>
        <time dateTime={new Date(timeStamp).toISOString()}> {time} </time>
      </div>
    )
  );
};

HeaderTimeStamp.propTypes = {
  timeStamp: number,
  prefix: string
};

export default HeaderTimeStamp;
