
import { useEffect, useState } from "react"
import useUser from "./useUser";
import useWhiteListedIP from "./useWhiteListedIp";
const useStorage = () => {
  const [checkUser, setCheckUser] = useState();
  const [activeProducts, setActiveProducts] = useState();
  const[whiteListed, setWhiteListed]= useState();
  const[chkUsr,hasActiveProduct]= useUser();
  const[listed]= useWhiteListedIP();
  const[allTagIds, setAllTagIds]=useState([]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    } else {
      const userValues = JSON.parse(window.localStorage.getItem("userValues"));
      setCheckUser(userValues)
      setAllTagIds({
        tagId_105046:localStorage.getItem("tagId_105046") || null,
        tagId_105047:localStorage.getItem("tagId_105047") || null,
        tagId_106320:localStorage.getItem("tagId_106320") || null,
        tagId_106321:localStorage.getItem("tagId_106321") || null,
        tagId_106322:localStorage.getItem("tagId_106322") || null,
        tagId_106323:localStorage.getItem("tagId_106323") || null,
        tagId_106324:localStorage.getItem("tagId_106324") || null,
        tagId_106325:localStorage.getItem("tagId_106325") || null,
      });
      if(listed){ /* remove this condition if not required to login user based on white-listed-ip */
        setCheckUser(true)
      }
      const activeProducts = JSON.parse(window.localStorage.getItem("activeProducts"));
      if(chkUsr){
        setActiveProducts(activeProducts);
        //setActiveProducts(false); /* enven if user logged in but has no active products, paywall blocks contents */
      }else{
        JSON.stringify(window.localStorage.setItem("activeProducts", false));
        setActiveProducts(!activeProducts);/* active product info. is available only when the user is logged in */
      }
      const getIp = JSON.parse(window.localStorage.getItem("ipUser"));
      setWhiteListed(getIp);
      //setWhiteListed(false);/* remove this commented code to check with/without white-listed-ip */
    }

  }, [checkUser,chkUsr, activeProducts,hasActiveProduct,listed,whiteListed])
  return [checkUser,activeProducts,whiteListed,allTagIds]
}
export default useStorage
