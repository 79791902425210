import React from "react";
import { Link } from "@quintype/components";
import AppStore from "../../basic/images/app-store-badge.png";
import "./app-store-badge.m.css";
import assetify from "@quintype/framework/assetify";

const AppStoreBadge = () => (
  <Link href="https://apps.apple.com/fi/app/ilkka-pohjalainen/id1493932582?l=fi" styleName="appstore-badge-link">
    <img src={`${assetify(AppStore)}`}
    alt="Lataa sovellus App Storesta"
    data-retina_logo_url=""
    styleName="app-store-badge" />
  </Link>
);

export { AppStoreBadge };

