import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import HeaderTimeStamp from "../../atoms/header-time-stamp";
import "./weather-widget.m.css";
import { string } from "prop-types";

import AdforeClient from "./adfore.js"
import CITIES from "./paikkakunta.json";

const WeatherWidget = ({currentCity}) => {

  const defaultCity = currentCity ? currentCity : CITIES[0];
  const defaultIcon = 'https://kuvat.tassa.fi/logos/weather/0.svg';
  const defaultTemp = '';
  const [hasLoaded, setHasloaded] = useState(false);
  const [ city, setCity ] = useState(defaultCity);
  const [ wicon, setWicon ] = useState(defaultIcon);
  const [ wdeg, setWdeg ] = useState(defaultTemp);
  const [isTrue, setIsTrue] = useState(false);
  const toggle=()=>setIsTrue(!isTrue);
  const cityList = CITIES;
  const [autoLocate, setAutoLocate] = useState(true);
  var fetchingData = false; // transient

  useEffect(() => {
    if (!document.getElementById('adforeapi')) {
      const script = document.createElement('script');
      script.id = 'adforeapi';
      script.src = 'https://sc.tassa.fi/api/ip_adfore_v2.js?key=aChbfVb0d75dvTV24aBRQ5OnG0zSWLlCa5Q4AR4M&v=5';
      script.async = true;
      script.onload = () => setHasloaded(true);
      document.body.appendChild(script);
    }

    if (!window.ac) {

      window.ac = AdforeClient;
      window.ac.init();
    }
    setCity(window.ac.getCity());
    if (window.adforeObj.weatherObj.ts && window.adforeObj.weatherObj.ts > new Date().getTime() - 600000) {
      setWicon(window.adforeObj.weatherObj.symbol);
      setWdeg(window.adforeObj.weatherObj.temp);
      setAutoLocate(false);

      console.log('AutoLocate -> false');
    }
    else {
      console.log(JSON.stringify(window.adforeObj));
    }

  }, []);

  useEffect(() => {
    if (hasLoaded === true) {
      if (autoLocate) {
        locate();
      }
      else {
        window.ac.pageView();
      }
    }
  }, [hasLoaded]);

  function locate(e) {
    //console.log('Locate called.');
    if (e) {
      e.preventDefault();
    }
    if(!fetchingData) {
      fetchingData = true;
      setTimeout(showDefaultCity, 5000);
      setCity('Paikannetaan...');
      if (window.ac) {
        window.ac.locate(handleResults);
      }
      else {
        console.log('ac not initiated');
      }
    }
  }

  function selectCity(c, e) {
    e.preventDefault();
    setCity(c); // make it faster
    setWicon(defaultIcon);
    setWdeg(defaultTemp);
    //console.log('Valittu ' + c);
    window.ac.selectCity(c, handleResults);
  }



  function handleResults(r) {
    fetchingData = false;
    //console.log('Handle result ' + r.locObj.city);
    setCity(r.locObj.city);
    setWicon(r.weatherObj.symbol);
    setWdeg(r.weatherObj.temp);
  }

  function showDefaultCity() {
    if (fetchingData) {
      //console.log('Haku kesken, oletukseen');
      window.ac.selectCity(defaultCity, handleResults);
      setTimeout(showLocFail, 1500);
    }
  }

  function showLocFail() {
    if (fetchingData) {
      fetchingData = false;
      setCity('Valitse paikkakunta');
    }
  }

  return  (
    <div styleName="today">
    <div styleName="weather-row">
      <div styleName="weather-icon"><img src={ wicon }/></div>
      <div styleName="weather-degrees">{ wdeg }</div>
      <h5>
      <HeaderTimeStamp timeStamp={Date.now()}/>
      </h5>
    </div>
    <div styleName="location">
      <div styleName="location-dropdown">
        <Link>
          <span onClick={()=>toggle()}><i className="ri-arrow-drop-down-line"></i></span>
        </Link>
        {isTrue&&<ul>
          {CITIES.map(c =>
            <a href="#" onClick={(e) => { selectCity(c, e); toggle(); }}><li>{c}</li></a>
          )}
          </ul>}
        </div>
      <div styleName="city">
      <span onClick={()=>toggle()}>{ city }</span>
      </div>
      <a href="#" onClick={locate}><i className="ri-map-pin-2-line"></i></a>
    </div>
    </div>
  );
};


WeatherWidget.propTypes = {
  currentCity: string
};

export { WeatherWidget };
