import React, { useState, useEffect, createRef } from "react";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { Search } from "../navbar-search";
import { WeatherWidget } from "../weather-widget";
import NavBarToggleBtn from "../../atoms/nav-bar-toggle-btn";
import ArrowDownIcon from "../../basic/images/arrow-down.svg";
import useUser from "../../../hook/useUser"
import "./menu-bar.m.css";
import { Login } from "../navbar-login";
import createUrl from "../../utils/createurl";
import useStorage from "../../../hook/useStorage";
import useLinks from "../../../hook/useLinks";

const MenuBar = ({ mainNav, menuItems, hamburgerMenu, pageType, currentPath }) => {

  const [firstParentId, setFirstParentId] = useState("");
  const [secondParentId, setSecondParentId] = useState("");
  const [goback, setGoback] = useState(true);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [index, setIndex] = useState("");
  const container = React.createRef();
  const [links] = useLinks("myaccount");
  const [checkUser] = useStorage();
  const [isTop, setIsTop] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const toggle=()=>setIsTrue(!isTrue);

  const showChild = (id, key) => {
    if (id === firstParentId) {
      setFirstParentId(id);
      setSecondParentId(null);
      setIsMegaMenuOpen(!isMegaMenuOpen);
      setIndex(key);
      setGoback(true);
      setIsActive(!isActive);
    }
    else {
      setFirstParentId(id);
      setSecondParentId(null);
      setIsMegaMenuOpen(true);
      setIndex(key);
      setGoback(true)
      setIsActive(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.removeEventListener("mousedown", handleClickOutside);
  }, [])

  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      setIsMegaMenuOpen(false);
      setIsActive(false);
      setIndex("");
    }
  };

  const showSecondChild = id => {
    setSecondParentId(id);
    setIsMegaMenuOpen(true);
    setGoback(!goback);
    setIsActive(true);
  };

  const onMenuToggle = (id) => setIndex(id);

  const showChildMenu = child => {
    return (
      (child.length >= 1 && isMegaMenuOpen === true) && (
        <div styleName="dropdown-list">
          <ul styleName="dropdown">
            {child.map(item => {

              return item.title && item.children.length < 1 ? (
                <li key={item.title} styleName="no-children">
                  {item.isExternalLink && !(item.completeUrl.includes("komiat.fi")) ? (
                    <a
                      styleName="menu-item-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.url || "/"}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link styleName="menu-item-link" href={item.completeUrl || "/"}>
                      {item.title}
                    </Link>
                  )}
                  <hr styleName="parent-divider" />
                </li>
              ) : (
                <li key={item.title} styleName="parent-link">
                  <a
                    styleName="menu-item-link-parent"
                    onClick={() => showSecondChild(item.id)}
                  >
                    {item.title} {item.children.length >= 1 && <i className="ri-arrow-down-s-line"></i>}

                  </a><hr styleName="parent-divider" />

                  {secondParentId === item.id ? showSecondChildMenu(item.children) : null}

                  <ul>
                    {item.children.map(item => {
                      return (
                        item.title && (
                          <li styleName={`second-child-menu-desktop ${item.title === "empty row" ? "empty-row" : ""}`} key={item.title}>
                            {item.isExternalLink && !(item.completeUrl.includes("komiat.fi")) ? (
                              <a
                                styleName="menu-item-link second-child-menu"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.completeUrl || "/"}
                              >
                                {item.title}
                              </a>
                            ) : (
                              <Link styleName="menu-item-link second-child-menu" href={item.completeUrl || "/"}>
                                {item.title}
                              </Link>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>

                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  };

  const showSecondChildMenu = child => {
    return secondParentId !== null && child.length >= 1 ? (
      <ul styleName={`${goback === true ? "hide-menu" : ""} `}>
        {child.map(item => {
                        //console.log("childit", item);
          return (
            item.title && (
              <li styleName={`second-child-menu-mobile ${item.title === "empty row" ? "empty-row" : ""}`} key={item.title}>
                {item.isExternalLink && !(item.completeUrl.includes("komiat.fi")) ? (
                  <a
                    styleName="menu-item-link second-child-menu"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.completeUrl || "/"}
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link styleName="menu-item-link second-child-menu" href={item.completeUrl || "/"}>
                    {item.title}
                  </Link>
                )}
              </li>
            )
          );
        })}
        <hr styleName="parent-divider" /></ul>
    ) : null;
  };
  useEffect(() => {
    var lastScrollTop = 0;
    document.addEventListener('scroll', () => {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsTop(isTop)
      } else {
        setIsTop(!isTop)
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      //console.log("pos", document.documentElement.scrollTop)
    });
  }, [])
  //console.log("istop", isTop)
  const currentSection = pageType === "section-page" ? currentPath.split("/")[1] : ""
  {/* <div styleName={isTop?"top-wrapper":"hide"}>*/ }
  return (
    <div styleName="wrapper">
      <div styleName="inner-wrapper">
        <div styleName="menu-group">
          <nav styleName="nav">
            <React.Fragment>
              {mainNav.length >= 1 && (
                <>
                <ul styleName="menu-list menu-list-left menu-list-left-desktop">
                  {mainNav.map(item => {
                    return item.children.length < 1 ? (
                      item.isExternalLink ? (
                        <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                          <a styleName="menu-item-link" href={item.completeUrl || "/"}>
                            {item.title}
                          </a>
                        </li>
                      ) : (
                        <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                          <Link styleName="menu-item-link" href={item.completeUrl || "/"}>
                            {item.title}
                          </Link>
                        </li>
                      )
                  ) : (
                    <li
                      styleName={`menu-list-item ${ currentSection === item["section-slug"] ? "active": ""}`}
                      key={item.id}
                    >
                      <a styleName="menu-item-link" onClick={() => showChild(item.id, index)}>
                        {item.title}
                        {item.children.length >= 1 &&  (
                          <i className={isActive && (firstParentId === item.id) ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                        )}
                      </a>
                      {(firstParentId === item.id) ? showChildMenu(item.children) : null}
                    </li>
                  );
                  })}
                </ul>
                <ul styleName="menu-list menu-list-left menu-list-left-tablet">
                {mainNav.slice(0,3).map(item => {
                  return item.children.length < 1 ? (
                    item.isExternalLink ? (
                      <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                        <a styleName="menu-item-link" href={item.completeUrl || "/"}>
                          {item.title}
                        </a>
                      </li>
                    ) : (
                      <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                        <Link styleName="menu-item-link" href={item.completeUrl || "/"}>
                          {item.title}
                        </Link>
                      </li>
                    )
                ) : (
                  <li
                    styleName={`menu-list-item ${ currentSection === item["section-slug"] ? "active": ""}`}
                    key={item.id}
                  >
                    <a styleName="menu-item-link" onClick={() => showChild(item.id, index)}>
                      {item.title}
                      {item.children.length >= 1 && (
                        <i className={isActive && (firstParentId === item.id) ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                      )}
                    </a>
                    {(firstParentId === item.id) ? showChildMenu(item.children) : null}
                  </li>
                );
                })}
              </ul>
              <ul styleName="menu-list menu-list-left menu-list-left-mobile">
                {mainNav.slice(0,1).map(item => {
                  return item.children.length < 1 ? (
                    item.isExternalLink ? (
                      <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                        <a styleName="menu-item-link" href={item.completeUrl || "/"}>
                          {item.title}
                        </a>
                      </li>
                    ) : (
                      <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                        <Link styleName="menu-item-link" href={item.completeUrl || "/"}>
                          {item.title}
                        </Link>
                      </li>
                    )
                ) : (
                  <li
                    styleName={`menu-list-item ${ currentSection === item["section-slug"] ? "active": ""}`}
                    key={item.id}
                  >
                    <a styleName="menu-item-link" onClick={() => showChild(item.id, index)}>
                      {item.title}
                      {item.children.length >= 1 && (
                        <i className={isActive && (firstParentId === item.id) ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                      )}
                    </a>
                    {(firstParentId === item.id) ? showChildMenu(item.children) : null}
                  </li>
                );
                })}
              </ul>
              </>
              )}
            </React.Fragment>

            <ul styleName="menu-list menu-list-right" ref={container}>
              {/*<li styleName="menu-list-item search">
                <Search />
                </li>*/}
              <li styleName="menu-list-item menu-login">
                {/*Kirjaudu sisään <span styleName="menu-icon"><i className="ri-login-box-line"></i></span>*/}
                {checkUser&&
                  <><Link styleName="user-link">
                    <span onClick={()=>toggle()}><i className="ri-user-line"></i><span styleName="omat-tiedot">Omat tiedot</span><i className="ri-arrow-down-s-line"></i></span>
                  </Link>
                  {isTrue&&<ul styleName="login-info">
                    <Link href={links}>

                        <li styleName="menu-list-item-login-info">Omat tiedot</li>

                      </Link>
                    <Link href="/tulo/logout">

                        <li styleName="menu-list-item-login-info">Kirjaudu ulos</li>

                      </Link>
                      </ul>}
                  </>}
                {!checkUser && <Login />}
              </li>
              {!checkUser && <li styleName="menu-list-item menu-tilaa">
                <a href="https://asiakaspalvelu.i-mediat.fi/komiat/tilaa" target="_blank" styleName="subscribe-link">Tilaa</a>
              </li>}
              {menuItems.map((menuItems, index) => {
                const getHomePage = (pageType === "Hamburger" && menuItems.title === "Talous") ? "active" : "";
                return (
                  menuItems.title && (
                    <React.Fragment>
                      {menuItems["title"] === "Hamburger" ? (
                        <li
                          styleName={`menu-list-item`}
                          key={menuItems.id}
                        >
                          <a styleName="hamburger-link" onClick={() => showChild(menuItems.id, index)}>
                            <span styleName="valikkotext">Valikko</span>
                             <NavBarToggleBtn
                              onMenuToggle={() => onMenuToggle(index)}
                              isMegaMenuOpen={isMegaMenuOpen}
                              id={index}
                            />
                            </a>
                          {firstParentId === menuItems.id ? showChildMenu(hamburgerMenu) : null}
                        </li>
                      ) : menuItems.children.length < 1 ? (
                        <li styleName={`menu-list-item ${getHomePage} ${currentSection === menuItems["section-slug"] ? "active" : ""}`} key={menuItems.id} >
                          {menuItems.isExternalLink && !(item.completeUrl.includes("komiat.fi")) ? (
                            <a
                              styleName="menu-item-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={menuItems.completeUrl || "/"}
                            >
                              {menuItems.title}
                            </a>
                          ) : (
                            <Link styleName="menu-item-link" href={menuItems.completeUrl || "/"}>
                              {menuItems.title}
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li
                          styleName={`menu-list-item ${getHomePage} ${currentSection === menuItems["section-slug"] ? "active" : ""}`}
                          key={menuItems.id}
                        >
                          <a styleName="menu-item-link" onClick={() => showChild(menuItems.id, index)}>
                            {menuItems.title}
                            {menuItems.children.length >= 1 && (
                              <img styleName="arrowDown" src={`${assetify(ArrowDownIcon)}`} />
                            )}
                          </a>
                          {(firstParentId === menuItems.id) ? showChildMenu(menuItems.children) : null}
                        </li>
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );

}

export default MenuBar;
