import React, { useEffect, useState } from "react";

import PT from "prop-types";
import { Link } from "@quintype/components";
import "./logout.m.css";
import createUrl from "../../utils/createurl";
import useLinks from "../../../hook/useLinks";
const LogoutBase = (props) => {
  const [links] = useLinks("myaccount");
  return (
    <React.Fragment>
      <ul styleName="logout-info">
                    <Link href={links}>

                        <li styleName="menu-list-item-logout-info">Omat tiedot</li>

                      </Link>
                    <Link href="/tulo/logout">

                        <li styleName="menu-list-item-logout-info">Kirjaudu ulos</li>

                      </Link>
        </ul>
    </React.Fragment>
  );

}
export { LogoutBase };

LogoutBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  logoutStyle: PT.object,
  id: PT.string,
  toggleLogoutForm: PT.func,
  innerRef: PT.object
};
