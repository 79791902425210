import React, { useState } from "react";
import PT from "prop-types";
import DOMPurify from 'dompurify';
import assetify from "@quintype/framework/assetify";

import SearchIcon from "../../basic/images/search.svg";
import CloseIcon from "../../basic/images/close.svg";

import "./search.m.css";

const SearchBase = (props) => {

  const [name, setName] = useState("");
 const handleChange = (e) => {
    const { value } = e.target
    const searchValuePurified = DOMPurify.sanitize(value);
    const valueTrimmed =searchValuePurified.replace(/ /g, "-");
    setName(valueTrimmed);
  }

 const handleSubmit = (event) => {
    event.preventDefault();
    window.location = `/search?q=${name}`;
  }

    return (
      <React.Fragment>
        <div id="searchBox" styleName="qt-search-form" style={props.formStyle} className="qt-search-forms">
          <div styleName="search-desktop">
            <form onSubmit={handleSubmit} method="get">
              <div styleName="input-group">
                <input
                  id={props.id}
                  ref={props.innerRef}
                  name='name'
                  value={name}
                  styleName="header-search"
                  placeholder="Hae.."
                  name="q"
                  required
                  onChange={(e) => handleChange(e)}
                />
                <span className="input-group-btn">
                  <button type="submit" styleName="btn-search">
                    <i className="ri-search-line"></i>
                  </button>
                </span>
              </div>
            </form>
          </div>
          <div styleName="close-icon" onClick={props.toggleSearchForm}>
          <i className="ri-close-line"></i>
          </div>
        </div>
        <button styleName="btn_search" onClick={props.toggleSearchForm} style={props.initialStyle}>
          <span styleName="search-text">Hae </span><i className="ri-search-line"></i>
        </button>
      </React.Fragment>
    );

}
export { SearchBase };

SearchBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  searchStyle: PT.object,
  id: PT.string,
  toggleSearchForm: PT.func,
  innerRef: PT.object
};
