import React from "react";
import { Link } from "@quintype/components";
import HeaderLogo from "../../basic/images/Komiat_logo_h38.png";
import assetify from "@quintype/framework/assetify";
import "./app-logo.m.css";

const AppLogo = () => (
  <Link href="/">
    <img src={`${assetify(HeaderLogo)}`}
    alt="Logo"
    data-retina_logo_url=""
    styleName="publisher-logo" />
  </Link>
);

export { AppLogo };

